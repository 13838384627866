import React from 'react';
import Modal from '../../Utilities/Modal';
import Uploader from '../photos/uploadPhotos';
import { Card, CardContent, Typography } from '@mui/material'; // Material-UI components for card

function UploadMukhiyaDetails({ selectedNode = [], onClose }) {
  console.log('selected node', selectedNode);
  return (
    <Modal toggle={onClose}>
      <Card
        style={{ width: '100%', margin: '20px auto', maxWidth: '600px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
      >
        <CardContent>
          <Typography variant="h6" component="div" style={{ textAlign: 'center', marginBottom: '20px' }}>
            Upload Mukhiya Details
          </Typography>
          <Uploader
            samajMitraNodeId={selectedNode?.uid}
            onUploadSuccess={(url, md5Code, mediaType) => updateData('photos', url, md5Code, mediaType)}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}

export default UploadMukhiyaDetails;
